.loadingPage {
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: center;
    align-items: center;
    font-family: AsianHiro;
    margin-left: 100px;
    margin-top: 100px;

    img {
        margin-top: 150px;
        margin-left: 40px;
        animation: bounce 2s infinite;
    }

    @media (orientation:portrait) {
        transform: translateX(-300px);

        img {
            margin-top: 100px;
        }
    }
}


.text {
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url('../../assets/Bubble.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 300px;
    height: 200px;
}

@keyframes bounce {
    0% {
        transform: scale(0.9) rotate(0deg);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.9) rotate(360deg);
        ;

    }
}