.recFrame {
    img {
        width: 150px;
        height: 150px;
    }

    .rec_top_left {
        position: fixed;
        top: 0px;
        left: 0px;
    }

    .rec_top_right {
        position: fixed;
        top: 0px;
        right: 0px;
        transform: scaleX(-1);
    }

    .rec_bottom_left {
        position: fixed;
        bottom: 0px;
        left: 0px;
        transform: scaleY(-1);
    }

    .rec_bottom_right {
        position: fixed;
        bottom: 0px;
        right: 0px;
        transform: scaleX(-1) scaleY(-1);
    }

    .controls {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 100px;
        width: 100%;

        button {
            box-shadow: none;
            border: none;
            color: white;
            width: 700px;
            height: 100px;
            background-color: rgba(0, 0, 0, 0);
            font-size: 32px;
            font-family: AgencyBold;
            background-image: url("../../assets/buttonFrame.png");
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            opacity: 0.8;

            @media (orientation: portrait) {
                width: 70%;
                font-size: 18px;
            }

            &:hover {
                opacity: 1;
                transform: scale(0.99);
                // background-color: rgba(20, 20, 20, 0.9);

                // outline: white solid 1px;
            }
        }

        animation : appearFromBottom 0.2s ease-in;
    }


}

@keyframes appearFromBottom {
    from {
        transform: translateY(100px)
    }

    to {
        transform: translateY(0px)
    }
}

@keyframes scaleUp {
    from {
        transform: scale(0.5)
    }

    to {
        transform: scale(1)
    }
}