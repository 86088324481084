body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  scrollbar-color: #111111 rgb(0, 247, 255);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #111111;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(0, 247, 255);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: white;
  }

  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}